import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Box from 'components/box';
import Head from 'components/head';
import Title from 'components/title';
import FullWidth from 'components/fullwidth';
import obr from '../images/majetek-01.jpg';
import nabidka1 from '../files/oznameni-o-konani-opakovane-drazby-76285.pdf';
import nabidka2 from '../files/drazebni-vyhlaska-horni-plana.pdf';
import nabidka3 from '../files/drazebni-vyhlaska-besednice.pdf';

const Sluzba = ({ data }) => (
  <Layout>
    <FullWidth>
      <Head pageTitle={data.majetekJson.title} />
      <div className="c1c1">
        <div className="column">
          <Title as="h2" size="large">
            {data.majetekJson.title}
          </Title>

          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html: data.majetekJson.content.childMarkdownRemark.html,
            }}
          />
        </div>
        <div className="column lp2">
          <img src={obr} alt="Prodej majetku ilustrace" />
        </div>
      </div>
      <Box>
        <h3 className="kontaktni-osoby">Ceník</h3>
        <p className="kontaktPriloha">Bezplatná služba</p>
      </Box>
      <Box>
        <h3 className="kontaktni-osoby">Kontaktní osoby</h3>
        <p className="kontaktPriloha">
          Kontaktní osoby jsou uvedeny v&nbsp;příloze.
        </p>
      </Box>

      <div className="cenik">
        <h3 className="kontaktni-osoby">Nabídka odprodeje majetku</h3>
        <div className="download-btn">
          <a href={nabidka1} className="homeButton">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="black"
              width="18px"
              height="18px"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z" />
            </svg>
            <span>Oznámení o konání opakované dražby 76285</span>
          </a>
        </div>
        <div className="download-btn">
          <a href={nabidka2} className="homeButton">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="black"
              width="18px"
              height="18px"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z" />
            </svg>
            <span>Dražební vyhláška Horní Planá</span>
          </a>
        </div>
        <div className="download-btn">
          <a href={nabidka3} className="homeButton">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="black"
              width="18px"
              height="18px"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z" />
            </svg>
            <span>Dražební vyhláška Besednice 8.&nbsp;9.&nbsp;2022</span>
          </a>
        </div>
      </div>
    </FullWidth>
  </Layout>
);

Sluzba.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Sluzba;

export const query = graphql`
  query MajetekQuery {
    majetekJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
      kontakty {
        name
        occupation
        phone1
        phone2
        email
      }
    }
  }
`;
